import fetch from 'cross-fetch';
import { fromJS, Map } from 'immutable';
import 'invariant';
import 'prop-types';
import { HTTP_STATUS_CODES, API_IDS_MAX_RESPONSE_TIMES, HEADERS_TO_NOT_SEND_AS_DBH_HEADERS, HTTP_METHODS } from '@dbh/api-constants';
import { ApiMaxResponseTimeExceededError, ApiRequestFailedBecauseOfNetworkError, ApiRequestError } from '@dbh/request-errors';
import { httpStatusCodePropType } from '@dbh/request-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { isServerSideRendering } from '@dbh/environment';
import { CalculateJSONSizeKB } from '@dbh/lodash-extra';
import getRequestTelemetry from '@dbh/request-telemetry';
import _isNaN from 'lodash/isNaN';
import 'react-immutable-proptypes';
import { invariantWarning } from '@dbh/invariant-errors-and-warnings';

/**
 * Returns `true` if the given content type is `application/json`.
 * @param {string?} contentType .
 * @return {boolean} .
 */const isContentTypeJson=withConformsTo("isContentTypeJson",[],a=>a&&a.includes("application/json"));var isContentTypeJson$1 = isContentTypeJson;

/**
 * Returns the content type of the given response.
 * @param {Object} fetchResponse .
 * @return {string} .
 */const getFetchResponseContentType=withConformsTo("getFetchResponseContentType",[],a=>a&&a.headers?a.headers.get("content-type"):void 0),isFetchResponseEmpty=withConformsTo("isFetchResponseEmpty",[],a=>{const{status:b}=a,c=getFetchResponseContentType(a);return [HTTP_STATUS_CODES.NO_CONTENT,HTTP_STATUS_CODES.RESET_CONTENT].includes(b)||!isContentTypeJson$1(c)});/**
 * Returns `true` if the given response is empty or `non-JSON`.
 * @param {Object} fetchResponse .
 * @return {boolean} .
 */var isFetchResponseEmpty$1 = isFetchResponseEmpty;

const getResponseHeadersValues=withConformsTo("getResponseHeadersValues",[],a=>{const b=a.get("dbh-be-cached-result-date"),c="true"===a.get("dbh-be-cached-result"),d="true"===a.get("dbh-be-authenticated-result"),e=a.get("dbh-be-content-encoding"),f=a.get("dbh-be-upstream-time"),g=a.get("content-length"),h=_isNaN(parseInt(g,10))?void 0:g/1e3,i="yes"===a.get("dbh-cf-is-cached-result"),j=a.get("dbh-cf-upstream-time"),k=a.get("content-encoding"),l=a.get("dbh-cf-to-be-network-time"),m=a.get("dbh-cf-to-be-compute-time"),n=a.get("dbh-cf-to-be-total-time"),o=!!a.get("dbh-cf-proxy-action"),p="yes"===a.get("dbh-cf-was-request-authenticated");return {beDateCachedResult:b,beIsCachedResult:c,beAuthenticatedResult:d,beContentEncoding:e,beUpstreamTime:f,contentEncoding:k,responseSizeKB:h,cfToBeNetworkTime:l,cfToBeComputeTime:m,cfToBeTotalTime:n,cfIsCachedResult:i,cfWorkerUpstreamTime:j,isProxiedByCf:o,cfWasRequestAuthenticated:p}});var getResponseHeadersValues$1 = getResponseHeadersValues;

/**
 * Builds the "request attributes" that contains the informations about the values
 * that contain the information about the values sent to the `API`, and other
 * useful request data.
 * @param {string} url The `API` url.
 * @param {Object?} options The `API` request options.
 * @param {Object} fetchResponse The not parsed response, received directly from
 * the `API` request.
 * @param {Object} timingsAndParsedFetchResponse .
 * @param {number} timingsAndParsedFetchResponse.startTime .
 * @param {number} timingsAndParsedFetchResponse.endTime .
 * @param {Object} timingsAndParsedFetchResponse.parsedFetchResponse .
 * @return {Immutable.Map} .
 */const buildRequestAttributes=withConformsTo("buildRequestAttributes",[],(a,b,c,d)=>{let{startTime:e,endTime:f,parsedFetchResponse:g}=d;const{status:h,headers:i}=c,{dbhHeaders:j,method:k,dryRun:l,isContentLengthCalculationEnabled:m}=b||{},n=isFetchResponseEmpty$1(c),o={isServerSideRendering:isServerSideRendering(),request:{url:a,options:b,method:k||"GET",timings:{start:e,end:f},dates:{},authenticated:!!j&&!!j.Authorization},response:{status:h,empty:n,beDateCachedResult:"",isCachedResult:!1,isAuthenticatedResult:!1},dryRun:l};let p;if(i){const{beDateCachedResult:a,responseSizeKB:b,beUpstreamTime:c,cfWorkerUpstreamTime:d,...e}=getResponseHeadersValues$1(i);if(p=d||c,o.response={...o.response,...e,beDateCachedResult:a,beUpstreamTime:c,cfWorkerUpstreamTime:d},a&&(o.request.dates.cached=new Date(a)),m){let a=0;if(g){// eslint-disable-next-line id-blacklist
const{data:b}=g;// eslint-disable-next-line id-blacklist
a=CalculateJSONSizeKB(b);}o.contentLengthKB=b||a;}}const{computeTime:q,totalTime:r,networkTime:s}=getRequestTelemetry({dbhBeUpstreamTime:p,startTime:e,endTime:f});return o.response={...o.response,computeTime:q,totalTime:r,networkTime:s},fromJS(o)});var buildRequestAttributes$1 = buildRequestAttributes;

/**
 * Returns the content of a `fetch` response.
 * @param {Object} response A `fetch` response.
 * @return {Object} Contains the extracted data and its "type".
 */const getJSON=withConformsTo("getJSON",[],async a=>{// We have to do this check (i.e the `dryRun` response is 200 without any JSON
// response) otherwise, due to how JavaScript's try/catch work inside a Promise,
// it is very difficult to catch the "it is not a JSON response" exception.
const b=a.headers.get("content-type"),c=isContentTypeJson$1(b);if(c){const b=await a.json();// eslint-disable-next-line id-blacklist
return {type:"json",data:b}}const d=await a.text();// eslint-disable-next-line id-blacklist
return {type:"text",data:d}});var getJSON$1 = getJSON;

/**
 * Returns a correct header key name, given a `DBH` header key name.
 * @param {string} key .
 * @return {string} .
 */const makeFetchHeaderKeyFromOurHeaderKey=withConformsTo("makeFetchHeaderKeyFromOurHeaderKey",[],a=>{const b=a.toLowerCase();return "authorization"===b?"Authorization":b.startsWith("dbh-")?b.replace(/dbh-/i,"Dbh-"):"Dbh-"+a});var makeFetchHeaderKeyFromOurHeaderKey$1 = makeFetchHeaderKeyFromOurHeaderKey;

/**
 * Builds the fetch headers with some important debugging constants.
 * @param {Object?} paramHeadersJS
 * @return {Object} .
 */const createFetchHeaders=withConformsTo("createFetchHeaders",[],a=>{const b=a||{};// Be careful with custom header values, having "special" characters in them
// may cause fetch and so ... the site, not to work (blank white page).
return b["Dbh-Version-Code"]="2025-02-14T20_54_47-a7d19723476534abb701ed1b3f7fed2e51518b95-production-bitbucket-8833",b["Dbh-Build-Date"]="2025-02-14T20_54_47",b});var createFetchHeaders$1 = createFetchHeaders;

const PACKAGE_NAME="@dbh/request";const DEFAULT_FETCH_OPTIONS={// We make, one time, the "default" fetch headers.
headers:createFetchHeaders$1({Accept:"application/json","Content-Type":"application/json"}),maxResponseTimeMs:API_IDS_MAX_RESPONSE_TIMES.default};

/**
 * Helper function to make the default `options` object to pass to `request`.
 * @param {Object?} options The `options` we want to send to the request; they
 * are expected to be in `fetch` format, but can also contain some special,
 * custom keys that we compute and don't pass directly to `fetch`, such as
 * `dbhHeaders`.
 * @param {boolean} isSSRParam Is the code executed during server side rendering.
 * @see {@link https://github.github.io/fetch/#Headers}
 * @return {Object} A request info.
 */const makeDefaultFetchOptions=withConformsTo("makeDefaultFetchOptions",[],(a,b)=>{if(!a)return DEFAULT_FETCH_OPTIONS;const{dbhHeaders:c,headers:d,body:e,...f}={...DEFAULT_FETCH_OPTIONS,...a};let g=e;const h={Accept:"application/json","Content-Type":"application/json",...d};if(c){const a="boolean"==typeof b?b:isServerSideRendering();Object.keys(c).forEach(b=>{const d=!!a||!HEADERS_TO_NOT_SEND_AS_DBH_HEADERS.includes(b);d&&(h[makeFetchHeaderKeyFromOurHeaderKey$1(b)]=c[b]);});}const i=createFetchHeaders$1(h);return "object"==typeof g&&(g=JSON.stringify(g)),{headers:i,body:g,...f}});var makeDefaultFetchOptions$1 = makeDefaultFetchOptions;

/**
 * Builds an error with extra information for the given response.
 * @param {Object} options .
 * @param {Object} options.jsonResponse .
 * @param {Object} options.requestOptions .
 * @param {Object} options.timings .
 * @param {Object?} options.responseHeaders .
 * @param {string?} options.rawTextResponse .
 * @param {string?} options.errorText .
 * @param {Immutable.Map} options.requestAttributes .
 * @param {boolean?} options.emptyResponse .
 * @param {boolean?} options.internetDisconnected .
 * @param {number?} options.statusCode .
 */const makeErrorWithResponse=withConformsTo("makeErrorWithResponse",[],a=>{let{jsonResponse:b,rawTextResponse:c,fetchOptions:d,errorText:e,timings:f,responseHeaders:g,requestAttributes:h,emptyResponse:i,internetDisconnected:j,statusCode:k}=a;const l=new Error(e);return l.apiResponse=b||c,l.emptyResponse=i,l.requestAttributes=h,l.internetDisconnected=j,l.statusCode=k,l.timings=f,l.responseHeaders=g,l.fetchOptions=d,l});var makeErrorWithResponse$1 = makeErrorWithResponse;

const warningServerErrorApiResponseWithoutErrors=()=>{invariantWarning(!1,"The server has returned an `API` response with an HTTP status code that means \"error\", but it did not return any errors in the response.");};var warningServerErrorApiResponseWithoutErrors$1 = warningServerErrorApiResponseWithoutErrors;

/**
 * Validates `API` response when the `API` call is made with `dryRun` (we use it
 * for async validation).
 * @param {string} url .
 * @param {(number|string)?} status .
 * @param {Object?} parsedFetchResponse .
 */const validateApiCallWithDryRun=withConformsTo("validateApiCallWithDryRun",[],(a,b,c)=>{if(c){// eslint-disable-next-line id-blacklist
const{data:d}=c;// eslint-disable-next-line id-blacklist
if(!d)return void warningServerErrorApiResponseWithoutErrors$1();// eslint-disable-next-line id-blacklist
const{dryRun:e}=d;!1===e&&console.error("(`@dbh/request`) An API (`"+a+"`) called with `dryRun: true` (validation mode) returned `dryRun: false`. Check in 'Network' that dryRun: true is passed and notify the Back End."),b===HTTP_STATUS_CODES.INTERNAL_ERROR&&e&&console.error("(`@dbh/request`) An API (`"+a+"`) called with `dryRun: true` (validation mode) returned HTTP status code 500. Please notify the Back End.");}});

/**
 * Requests a `URL`, returning a `Promise`.
 * @param {string} url The URL we want to request.
 * @param {Object?} options The options we want to pass to "fetch", plus some
 * special, DBH options inside that will be computed by `makeDefaultFetchOptions`.
 * @return {Object} The response data.
 */const request=withConformsTo("request",[],async(a,b)=>{const c=makeDefaultFetchOptions$1(b);// Initialize response in case the `fetch` fails because of
// a lost network connection.
let d,e={};const f=performance.timeOrigin+performance.now();// Catch network errors, i.e. lost connection.
try{// `makeDefaultFetchOptions` includes some debugging constants and the default
// headers for JSON requests. We assume that if `options` were passed, they were
// created using the `makeOptionsWith...` functions in `./helpers` and so already
// include that "default" stuff.
e=await fetch(a,{...c,signal:AbortSignal.timeout(c.maxResponseTimeMs)});}catch(a){d=a;}const g=performance.timeOrigin+performance.now(),h={startTime:f,endTime:g};let i=Map();const{status:j,headers:k}=e,l=[a,b,e,h];if(d){i=buildRequestAttributes$1(...l);const a={errorText:d.message,requestAttributes:i,timings:h,fetchOptions:c,fetchError:d,emptyResponse:!0},{name:b}=d,e=["AbortError","TimeoutError"].includes(b);throw e?d=new ApiMaxResponseTimeExceededError(a):(a.internetDisconnected=!0,d=new ApiRequestFailedBecauseOfNetworkError(a)),d}if(j===HTTP_STATUS_CODES.OK){const a=await getJSON$1(e),{data:b,type:d}=a;// eslint-disable-next-line id-blacklist
// eslint-disable-next-line id-blacklist
if("json"===d)return l[3].parsedFetchResponse=a,i=buildRequestAttributes$1(...l),{apiResponse:b,responseHeaders:k,requestAttributes:i,timings:h,fetchOptions:c};if("text"===d)return i=buildRequestAttributes$1(...l),{emptyResponse:!0,requestAttributes:i,timings:h,fetchOptions:c,responseHeaders:k}}// Bad gateway is when one of the `nginx` servers on the back end
// side is malfunctioning.
if([HTTP_STATUS_CODES.NO_CONTENT,HTTP_STATUS_CODES.RESET_CONTENT,HTTP_STATUS_CODES.BAD_GATEWAY].includes(j))return i=buildRequestAttributes$1(...l),{emptyResponse:!0,requestAttributes:i,timings:h,fetchOptions:c,responseHeaders:k,statusCode:j};if([HTTP_STATUS_CODES.NOT_FOUND,HTTP_STATUS_CODES.INTERNAL_ERROR,HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY,HTTP_STATUS_CODES.CLIENT_ERROR,HTTP_STATUS_CODES.CONFLICT,HTTP_STATUS_CODES.NOT_AVAILABLE].includes(j)){const d=await getJSON$1(e);// We do this ugly non functional assignment for speed.
l[3].parsedFetchResponse=d,i=buildRequestAttributes$1(...l);const f={errorText:e.statusText,requestAttributes:i,statusCode:j,timings:h,fetchOptions:c,responseHeaders:k},{dryRun:g}=b||{};g&&validateApiCallWithDryRun(a,j,d),d.data?"json"===d.type?f.jsonResponse=d.data:"text"===d.type&&(f.rawTextResponse=d.data):f.emptyResponse=!0;const m=makeErrorWithResponse$1(f);throw new ApiRequestError(m)}});var request$1 = request;

/**
 * Returns `true` if the given status code is a redirect status code.
 * @param {number} statusCode .
 * @return {boolean} .
 */const isRedirectStatusCode=withConformsTo("isRedirectStatusCode",["statusCode",httpStatusCodePropType.isRequired],a=>[HTTP_STATUS_CODES.PERMANENT_REDIRECT,HTTP_STATUS_CODES.TEMPORARY_REDIRECT].includes(a));var isRedirectStatusCode$1 = isRedirectStatusCode;

/**
 * Helper function to build an `options` object to pass to `request`,
 * to send an object encoded as `JSON` in the body.
 * @param {string} method The `HTTP` method of the request.
 * @param {Object} body The data we want to encode as `JSON` in the body.
 * @param {Object?} options The `options` to pass to `makeDefaultFetchOptions`.
 * @return {Object} To be passed to `request`.
 */const createFetchOptionsWithBody=withConformsTo("createFetchOptionsWithBody",[],(a,b,c)=>makeDefaultFetchOptions$1({method:a,mode:"cors",body:JSON.stringify(b),...c}));var createFetchOptionsWithBody$1 = createFetchOptionsWithBody;

const createRequestMethod=withConformsTo("createRequestMethod",[],a=>withConformsTo("createRequestMethod",[],async(b,c,d)=>request$1(b,createFetchOptionsWithBody$1(a,c,d))));// Use the following "shortcut" functions when possible.
const requestWithBodyPOST=createRequestMethod(HTTP_METHODS.POST);const requestWithBodyPATCH=createRequestMethod(HTTP_METHODS.PATCH);const requestWithBodyDELETE=createRequestMethod(HTTP_METHODS.DELETE);const requestWithBodyPUT=createRequestMethod(HTTP_METHODS.PUT);

export { DEFAULT_FETCH_OPTIONS, PACKAGE_NAME, createFetchOptionsWithBody$1 as createFetchOptionsWithBody, isRedirectStatusCode$1 as isRedirectStatusCode, request$1 as request, requestWithBodyDELETE, requestWithBodyPATCH, requestWithBodyPOST, requestWithBodyPUT, warningServerErrorApiResponseWithoutErrors$1 as warningServerErrorApiResponseWithoutErrors };
